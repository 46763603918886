
.swiperMain {
 

height: 100vh;
display: flex;
flex-direction: column;
}

 swiper-slide {
    
    max-width: 300px;
    max-height: 300px;
 }

    .project-image {
     
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease, filter 0.3s ease;
    }
  

.project-image:hover {
    transform: scale(1.25); /* Zoom in to 1.25 times the original size */
    filter: blur(2px); /* Add blur effect */
}





.overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: black;
    font-size: 30px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 4;
}

swiper-slide:hover .overlay-text {
    opacity: 1;
}

swiper-slide:hover .overlay-text {
    opacity: 1;
}




swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
    margin: 0 10% 0 10%
  }

  swiper-slide img {
    display: block;
    width: 100%;
  }