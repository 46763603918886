.overlayColStart {
    flex-direction: column;
    width: 33%;
    height: 100%;
   
    position: relative;
    z-index: 3;
   


}
@media only screen and (max-width: 600px) {
    .overlayColStart {
        width: 100%;
    }
}

.overlayColEnd {
    flex-direction: column;
    width: 33%;
    height: 100%;
   
    position: relative;
    z-index: 3;
   


}

@media only screen and (max-width: 600px) {
    .overlayColEnd {
       display: none;
    }
}

