


.main {
    overflow-x: hidden;

}
 
.this-is-tab-content {
    overflow: hidden;
}

.first-tab {
    height: 100vh;
    z-index: 200;
}

.starsBG {
    /* background-image: url('../../images/spaceBackground4.png');
    background-size: cover; */
    background-color: black;
}

 





@media (max-width: 576px) { 
       
    
    .main{ 
        color: rgb(255,193,7);
            display: flex;
            flex-direction: column;
            
        }
    
        .image-thumb1 {
            max-width: 50px !important;
            max-height: 50px !important;
            margin-top: 5%;
            margin-bottom: 10%;
        }
    
        .navbar1{
            display: flex;
        }

        .navLinks{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
        }
        
        .content-pane {
            display:flex;
            flex-direction: column;
            width: 100%;
            height: 100vh;
 
           
        }

        .project-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
}
    
  


  

@media (min-width: 577px) {

.main{ 
    color: rgb(255,193,7);
        display: flex;
        
    }
    .introBar {
        height: 15%;
        margin-bottom: 5%;
    }

    .image-thumb1 {
        max-width: 100px !important;
       
    }

    .navbar1{
        display: flex;
        flex-direction: column;
      width: 20% !important;
      

    }
    .navLinks{
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }
    
    .content-pane {
        display:flex;
        flex-direction: column;
        width: 100%;
        height: 100vh;
        
       
       
    }
   
     .page-container {
        display:flex;
        flex-direction: row;
        height: 100vh;

    }

    .project-container {
        display:flex;
        flex-direction: row;
        justify-content:space-around;
        align-items: center;
        height: 100vh;
    }
}




 

 

  